
const Network = {
    handleError: function (e) {
        console.log("NET ERR: ", e);
        if (e.code != undefined && e.code === 403) {
            console.log('FAIL: ',e.cause);
            let state = JSON.parse(localStorage.getItem('state'));
            state.authorization = {};
            localStorage.setItem('state', JSON.stringify(state));
            window.location.reload();
        }
    },
    json: function (resp) {
        if (resp.status === 403) {
            console.log("NEED AUTH");
            throw {
                cause: 'authorization required',
                code: 403
            };

        }
        console.log('FETCH RESP', resp);
        return resp.json();
    },
    loadProjects: function (token, success) {
        fetch('/api/project/list', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)

    },
    loadOneProjects: function (token, id, success) {
        fetch('/api/project/id/' + id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)

    },
    loadOneProjectReport(token,project,start,end,success){

        let url='/api/project/report/project/'+project+'/'+start.ymd()+'/'+end.ymd();
        console.log('load',url)
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)
    },
    createProject: function (token, values, success) {
        let url = "/api/project/create";
        let method = "POST";
        console.log('NET CREATE PROJ');


        fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token

            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(values) // body data type must match "Content-Type" header
        }).then(Network.json)
            .then(success)
            .catch(Network.handleError);
    },
    updateProject: function (token, data, success) {

        let url = "/api/project/edit";
        console.log('start update project;')

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify(data)
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)
    },
    projectRemove: function (token, id, success) {

        let url = "/api/project/remove/" + id;
        console.log('start delete project;');

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,

            },
            method: "DELETE"
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)
    },

    loadOneTask: function (token, id, success) {
        fetch("/api/task/id/" + id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError);

    },
    loadTasks: function (token, success) {


        var url = "/api/task/list"

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)
    },
    createTask: function (token, values, success) {
        var url = "/api/task/create";
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({name: values.name, projectId: values.projectId})
        }).then(Network.json).then(success).catch(Network.handleError);
    },
    updateTask: function (token, values, success) {

        var url = "/api/task/update";
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({id: values.id, name: values.name, projectId: values.projectId})
        }).then(Network.json).then(success).catch(Network.handleError);
    },
    taskRemove: function (token, id, success) {

        let url = "/api/task/remove/" + id;
        console.log('start delete task;');

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,

            },
            method: "DELETE"
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(function (error) {
                Network.handleError(error);
                alert('Have you deleted all actions for task?')
            })
    },
    loadHolidays: function (token, success) {
        var url = "/api/holiday/list"

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError);
    },
    createHoliday: function (token, values, success) {
        let url = "/api/holiday/create";
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(values)
        }).then(Network.json).then(success).catch(Network.handleError);
    },
    removeHoliday: function (token, id, success) {

        let url = "/api/holiday/remove/" + id;
        console.log('start delete holiday;');

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,

            },
            method: "DELETE"
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)
    },

    loadActions: function (token, userId, start, end, success) {

        var url = "/api/action/report/" + userId + "/" +
            start.ymd() + "/" + end.ymd();

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError)
    },
    loadClients: function (token, success) {
        let url = "/api/client/list";
        console.log('fetching client list;')
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError);
    },
    loadUsers: function (token, success) {
        let url = "/api/user/list";
        console.log('fetching user list;')

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError);
    },
    loadOneUser: function (token, id, success) {
        console.log("fetching user for edit "+id)
        fetch("/api/user/id/" + id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError);

    },
    createUser: function (token, values, success) {
        let url = "/api/user/create";
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                email: values.email,
                name: values.name,
                password: values.pwd,
                priveleged: values.priveleged
            })
        }).then(Network.json).then(success).catch(Network.handleError);
    },
    updateUser: function (token, values, success) {
        let url = "/api/user/update";
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id:values.id,
                email: values.email,
                name: values.name,
                password: values.pwd,
                priveleged: values.priveleged
            })
        }).then(Network.json).then(success).catch(Network.handleError);
    },
    loadProjectsForUser(token, userId, success) {
        let url = "/api/project/list/user/" + userId;
        console.log('fetching project list;')

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(success) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError);
    },
    setUserProjects(token, values, success) {
        let url = "/api/user/update/projects";
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(values)
        }).then(Network.json).then(success).catch(Network.handleError);
    },
    loadByProjectsReport(token, start,end,success) {
        let url = "/api/project/report/" + start.ymd()+"/"+end.ymd();
        console.log('fetching report for project list;')

        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(Network.json)
            .then(function(data){console.log('X',data,success); success(data)}) // JSON-строка полученная после вызова `response.json()`
            .catch(Network.handleError);
    }
};

export default Network;