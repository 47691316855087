import React, {Component} from "react";

import './App.css';
import NavigationBar from "./components/navigation/NavigationBar";
import ModalWin from "./components/ModalWin";
import {connect} from 'react-redux'
import MainLayout from './components/layout/MainLayout'
import NewActionForm from "./components/form/NewActionForm";
import EditActionForm from "./components/form/EditActionForm";

import DateFormPopup from "./components/DateFormPopup";
import AddProjectFormPopup from "./components/form/AddProjectFormPopup";
import EditProjectFormPopup from "./components/form/EditProjectFormPopup";
import AddTaskFormPopup from "./components/form/AddTaskFormPopup";
import EditTaskFormPopup from "./components/form/EditTaskFormPopup";
import HolidayFormPopup from "./components/form/HolidayFormPopup";
import AddUserFormPopup from "./components/form/AddUserFormPopup";
import EditUserFormPopup from "./components/form/EditUserFormPopup";
import EditUserProjectsPopup from "./components/form/EditUserProjectsPopup";

class App extends Component {
    render() {

        let newform = null;
        if (this.props.showActionForm) {
            newform = <NewActionForm day={this.props.formDay} onSubmit={function () {
            }}/>;
            console.log("---- show modal form ----")
        }


        if (this.props.showEditActionForm) {
            newform = <EditActionForm/>
            console.log('---show edit action--')
        }

        return (
            <div className="App">
                <header className="App-header">
                    <NavigationBar/>


                </header>
                <ModalWin show={this.props.loginDisplay}/>
                <MainLayout/>
                {newform}


                {this.renderForms()}



            </div>
        );
    }

    renderForms() {


        if (this.props.UI.showEditProjectForm) {
            return <EditProjectFormPopup/>
        }
        if (this.props.UI.showAddProjectForm) {
            return <AddProjectFormPopup/>
        }
        if (this.props.UI.showAddTaskForm) {
            return <AddTaskFormPopup/>
        }
        if (this.props.UI.showEditTaskForm) {
            return <EditTaskFormPopup/>
        }
        if (this.props.UI.showAddHolidayForm) {
            return  <HolidayFormPopup/>
        }
        if (this.props.UI.actionListDateFormShow) {
            return  <DateFormPopup/>
        }
        if(this.props.UI.showAddUserForm){
            return <AddUserFormPopup/>
        }
        if(this.props.UI.showEditUserForm){
            return <EditUserFormPopup/>
        }
        if(this.props.UI.showUserProjectsForm){
            return <EditUserProjectsPopup/>
        }
        return null;
    }

}


const mapStateToProps = function (state) {

    return {
        authorized: state.authorization.token != null,
        loginDisplay: state.loginForm.visible,
        showActionForm: state.UI.showActionForm,
        showEditActionForm: state.UI.showEditActionForm,
        UI: state.UI

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginClick: id => {
            dispatch({type: "nothing"})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
