import React, {Component} from "react";
import {Formik} from "formik";


import actions from "../../actions";
import {connect} from "react-redux";
import Network from "../../Network";




class UserForm extends Component {

    render() {
        // let actionId = this.props.actionId === undefined ? "" : this.props.actionId;

        // console.log('formik:::',this.props.id);
        return (
            <Formik
                initialValues={{

                    name: this.props.name,
                    email: this.props.email,
                    id: this.props.id,
                    priveleged: this.props.priveleged,
                }}

                onSubmit={(values, {setSubmitting}) => {
                    let that=this;
                    setTimeout(() => {
                        let v = Object.assign({}, values);
                        // console.log("VALUES")
                        // console.log(values)
                        // console.error(v);

                        // alert(JSON.stringify(v, null, 2));


                        // Network.createHoliday(that.props.token, v, function (data) {
                        //     Network.loadHolidays(that.props.token, that.props.holidaysLoaded);
                        // })

                        // console.log(v);
                        setSubmitting(false);
                        if(v.id==0) {
                            Network.createUser(that.props.token, v, function (data) {
                                Network.loadUsers(that.props.token, that.props.usersLoaded);
                            });
                        }else{
                            Network.updateUser(that.props.token,v,function (data) {
                                Network.loadUsers(that.props.token, that.props.usersLoaded);
                            });
                        }
                        this.props.close();
                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <label
                                className={"col-sm-9 col-form-label"}>Name</label>

                            <input className={"form-control"}
                                   type="text"
                                   name="name"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.name}
                            />
                        </div> <div className="form-group">
                        <label
                            className={"col-sm-9 col-form-label"}>Email</label>

                        <input className={"form-control"}
                               type="email"
                               name="email"
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.email}
                        />
                    </div>

                        <div className={"form-group"}>
                            <label
                                htmlFor="priveleged" className={"col-sm-9 col-form-label"}>Admin</label>
                            <input className={"form-control"}
                                   type="checkbox"
                                   name="priveleged"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   checked={values.priveleged}
                                   value={true}

                            />
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor="pwd" className={"col-sm-9 col-form-label"}>
                                Password (leave empty - will not change)
                            </label>
                            <input className={"form-control"}
                                   type="text"
                                   name="pwd"
                                   onChange={handleChange}
                                   onBlur={handleBlur}

                            />

                        </div>

                        <button type="submit" disabled={isSubmitting}
                                className="btn btn-primary mb-2"
                        >
                            Submit
                        </button>
                    </form>
                )}
            </Formik>);

    }


}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,

    }
}

const mapDispatchToProps = dispatch => {
    return {

        usersLoaded: (data) => dispatch(actions.user.usersLoaded(data)),
        // tasksLoaded: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.task.tasksLoaded(data))
        // },
        // closeNewActionForm: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.UI.closeActionForm(data))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
