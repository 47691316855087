import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import actions from "../actions";
import Network from "../Network";



import './ClientsBody.css'

class ClientsBody extends Component {


    constructor(props, context) {
        super(props, context);
        this.clickHeader = this.clickHeader.bind(this);
        this.clickAdd = this.clickAdd.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.clickRemove = this.clickRemove.bind(this);
    }

    clickRemove(e){
        let id = e.target.dataset.id;
        console.log(e.target,e.target.dataset);
        if(!window.confirm("Sure?")){
            return false;
        }
        let url = "/api/client/remove/"+id;
        console.log('start delete client;')
        let that = this;
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.props.token,

            },
            method: "DELETE"
        })
            .then(response => response.json())
            .then(function (data) {
                that.loadClients();
            }) // JSON-строка полученная после вызова `response.json()`
            .catch(error => console.error(error))
    }

    clickEdit(e){
        let id = e.target.dataset.id;
        let name = window.prompt("New name");

        if(name===null || name===undefined){
            return false;
        }
        let url = "/api/client/edit";
        console.log('start update client;')
        let that = this;
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.props.token,
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify({name: name,id:id})
        })
            .then(response => response.json())
            .then(function (data) {
                that.loadClients();
            }) // JSON-строка полученная после вызова `response.json()`
            .catch(error => console.error(error))
    }
    clickHeader(e) {
        let clientId = e.target.dataset.id;
        console.log('client id = ' + clientId);
        let elem = document.querySelector(".client-body-" + clientId);
        elem.classList.toggle("hidden-body");
    }

    clickAdd(e) {
        let name = window.prompt("client name");

        let url = "/api/client/add";
        console.log('start fetch client list;')
        let that = this;
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.props.token,
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({name: name})
        })
            .then(response => response.json())
            .then(function (data) {
                that.loadClients();
            }) // JSON-строка полученная после вызова `response.json()`
            .catch(error => console.error(error))
    }

    render() {
        if (this.props.userInfo.priveleged === "false") {
            return <div>
                <div className={"row"} style={{height: "35px"}}></div>
                <h1>Client</h1>
                <h2> Access denied </h2>
            </div>;
        }

        function sortTask(i1, i2) {
            if (i1.project.client.name > i2.project.client.name) return 1;
            if (i1.project.client.name < i2.project.client.name) return -1;
            if (i1.project.name > i2.project.name) return 1;
            if (i1.project.name < i2.project.name) return -1;
            if (i1.name > i2.name) return 1;
            if (i1.name < i2.name) return -1;
            return 0;
        }

        this.props.task.items.sort(sortTask);

        return <div>
            <div className={"row"} style={{height: "35px"}}></div>

            <h1>Projects</h1>
            <button type="button" className="btn btn-primary" onClick={this.clickAdd}>Add client</button>

            <div>
                {this.props.client.items.map(it => {
                    return <Fragment key={it.id}>
                        <div className={"client-grid"}>
                            <div
                                className={"cell"}
                                onClick={this.clickHeader}
                                data-id={it.id}
                            >{it.name}</div>
                            <div className={"cell cell-edit"} data-id={it.id} onClick={this.clickEdit}>
                                {/*<img src="/icons/edit.svg" data-id={it.id}  className="edit-icon"/>*/}
                            </div>
                            <div className={"cell cell-del"} data-id={it.id} onClick={this.clickRemove}>

                            </div>
                        </div>
                        <div className={"client-grid"}>
                            <div className={"hidden-body client-body client-body-" + it.id}>this is {it.name}</div>
                        </div>
                    </Fragment>
                })}
            </div>

        </div>
    }

    componentDidMount() {
        if (this.props.userInfo.priveleged === "false") {
            return ;
        }

        this.loadClients();
    }

    loadClients() {
        Network.loadClients(this.props.token,this.props.clientsLoaded);

    }



}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userInfo: state.authorization.payload,

        task: state.task,
        client: state.client,
        // userId: state.authorization.payload.id,
        // start: new Date(state.UI.actionsListStart),
        // end: new Date(state.UI.actionsListEnd),
        // actions: state.action,
        // formDay: state.UI.newActionDay,
        // showActionForm: state.UI.showActionForm
    }
};

const mapDispatchToProps = dispatch => {
    return {

        tasksLoaded: (data) => {
            // console.log('tasks here');
            return dispatch(actions.task.tasksLoaded(data))
        },
        clientsLoaded: (data) => {
            console.log('clients here');
            return dispatch(actions.client.clientsLoaded(data))
        },
        //
        // actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),
        // addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        // editAction: (id) => dispatch(actions.UI.editActionForm(id))
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ClientsBody);