import React, {Component} from "react";
import {connect} from "react-redux";
import ProjectList from "./project/ProjectList";
import NavigationLink from "./NavigationLink";
import actions from "../../actions";


class LeftNavigation extends Component {


    constructor(props, context) {
        super(props, context);
        this.clickHandler=this.clickHandler.bind(this);
    }

    clickHandler(id){
        this.props.tabActivate(id);
    }

    render() {
        if (!this.props.authorized) return null;
        return <nav className="col-md-2 d-none d-md-block  sidebar">
            <div className="sidebar-sticky App-sidebar sticky-top" style={{top:'60px'}}>
                <div className={"row"} style={{height:"35px"}}></div>

                <h2 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Reports</span>
                </h2>
                <ul className="nav flex-column mb-2">

                    <NavigationLink
                        id={"byproject"}
                        active={this.props.tab === 'byproject'}
                        onClick={this.clickHandler}
                        name="By Projects"/>
                    <NavigationLink
                        id={"lastweek"}
                        active={this.props.tab === 'lastweek'}
                        onClick={this.clickHandler}
                        name="Last week"/>
                    <NavigationLink
                        id={"byuser"}
                        active={this.props.tab === 'byclient'}
                        onClick={this.clickHandler}
                        name="By user"/>

                </ul>
                <h2 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Project reports</span>
            </h2>

                <ProjectList/>

            </div>
        </nav>;
    }


}


const mapStateToProps = function (state) {

    return {
        authorized: state.authorization.token != null,
        loginDisplay: state.loginForm.visible,
        tab: state.UI.activeTab,
    }
}

const mapDispatchToProps = dispatch => {
    return {

        tabActivate: function (tabId) {
            dispatch(actions.UI.setActiveTab(tabId))
        },
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);