import React, {Component} from "react";
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import LoginForm from "./form/LoginForm";


const RemoteSubmitButton = ({ dispatch }) => (
    <button
        type="button"
        className="btn btn-primary"
        onClick={() => dispatch(submit('login'))}
    >
        Login
    </button>


)
//                                  ^^^^^^^^^^^^ name of the form

let SubmitButton =  connect()(RemoteSubmitButton)





class ModalWin extends Component{
    constructor(props){
        super(props);
        this.customSubmit = this.customSubmit.bind(this);
        this.disabled = false;
    }


    //@TODO  make this binding, copy code from tosubmit(), take dispatch on then(){ HERE }
    customSubmit(values){
        if(values == undefined){
            return false;
        }
       console.log(' ------- SUBMIT ------')
        this.disabled = true;

        console.log(values);


        fetch("/api/auth/token", {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(values) // body data type must match "Content-Type" header
        }).then((response) => {
            return response.json();
        })
            .then((data) => {
                console.log(data);
                let token = data.token;
                this.props.setToken(token);
                this.props.closeWindow();

            });

        return false;
    }
    render(){

        let modstyle =  {display:"none"};
        if(this.props.loginDisplay){
            modstyle.display = "block";
        }

        return (
            <div className="modal" tabIndex="-1" role="dialog" style={modstyle}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Please Log In</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeWindow}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <LoginForm  onSubmit={this.customSubmit}/>
                        </div>
                        <div className="modal-footer">
                            <SubmitButton/>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"  onClick={this.props.closeWindow}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

       );
    }
}




const mapStateToProps = function(state) {

    return {
        authorized: state.authorization.token!=null,
        loginDisplay: state.loginForm.visible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeWindow: function() {
            console.log("login close")
            dispatch({type:"LOGIN_HIDE"})
        },
        setToken: function(token){
            dispatch({type:"AUTHORIZE",token:token});
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModalWin);