import React, {Component} from "react";
import {Formik} from "formik";


import DatePicker, {registerLocale} from "react-datepicker"
import ru from "date-fns/locale/ru"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import actions from "../../actions";
import {connect} from "react-redux";
import Network from "../../Network";
registerLocale("ru", ru); // register it with the name you wa



class HolidayForm extends Component {

    render() {
        // let actionId = this.props.actionId === undefined ? "" : this.props.actionId;

        // console.log('formik:::',this.props.id);
        return (
            <Formik
                initialValues={{

                    day: new Date(),
                    holiday: true,
                    name: "",
                }}

                onSubmit={(values, {setSubmitting}) => {
                    let that=this;
                    setTimeout(() => {
                        let v = Object.assign({}, values);
                        // console.log("VALUES")
                        // console.log(values)
                        // console.error(v);
                        v.day = values.day.ymd();
                         // alert(JSON.stringify(v, null, 2));


                        Network.createHoliday(that.props.token, v, function (data) {
                            Network.loadHolidays(that.props.token, that.props.holidaysLoaded);
                        })

                        console.log(v);
                        setSubmitting(false);

                        this.props.close();
                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <label
                                className={"col-sm-9 col-form-label"}>Date for
                                action</label>
                            <hr/>
                            <DatePicker
                                selected={values.day}
                                dateFormat="yyyy-MM-dd"
                                inline

                                showPreviousMonths
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="day"
                                todayButton="Today"
                                locale="ru"
                                onChange={date => setFieldValue('day', date)}
                            />
                        </div>

                        <div className={"form-group"}>
                            <label
                                htmlFor="holiday" className={"col-sm-9 col-form-label"}>holiday</label>
                            <input className={"form-control"}
                                   type="checkbox"
                                   name="holiday"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   checked={values.holiday}
                                   value={true}

                            />
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor="name" className={"col-sm-9 col-form-label"}>
                                Description
                            </label>
                            <input className={"form-control"}
                                   type="text"
                                   name="name"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.description}
                            />

                        </div>

                        <button type="submit" disabled={isSubmitting}
                                className="btn btn-primary mb-2"
                        >
                            Submit
                        </button>
                    </form>
                )}
            </Formik>);

    }


}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,

    }
}

const mapDispatchToProps = dispatch => {
    return {

        holidaysLoaded: (data) => dispatch(actions.holiday.holidaysLoaded(data)),

        // tasksLoaded: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.task.tasksLoaded(data))
        // },
        // closeNewActionForm: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.UI.closeActionForm(data))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HolidayForm);
