import React, {Component} from "react";
import {Formik,FieldArray} from "formik";


import actions from "../../actions";
import {connect} from "react-redux";
import Network from "../../Network";




class UserProjectsForm extends Component {

    render() {
if(this.props.projects === undefined) return null;
        // let actionId = this.props.actionId === undefined ? "" : this.props.actionId;


        let projects=this.props.projects;
        let projectIds = []
        projects.forEach(function (it) {
            if(it.assigned){
                projectIds.push(it.project.id);
            }
        })
        return (
            <Formik
                initialValues={{

                   userId: this.props.userForEdit.id,
                    projectIds: projectIds

                }}

                onSubmit={(values, {setSubmitting}) => {
                    let that=this;
                    setTimeout(() => {
                        let v = Object.assign({}, values);
                        // console.log("VALUES")
                        // console.log(values)
                        // console.error(v);

                        // alert(JSON.stringify(v, null, 2));


                        // Network.createHoliday(that.props.token, v, function (data) {
                        //     Network.loadHolidays(that.props.token, that.props.holidaysLoaded);
                        // })

                        console.log(v);
                        setSubmitting(false);
                        that.props.close();
                         Network.setUserProjects(that.props.token,v,function(){});
                        // if(v.id==0) {
                        //     Network.createUser(that.props.token, v, function (data) {
                        //         Network.loadUsers(that.props.token, that.props.usersLoaded);
                        //     });
                        // }else{
                        //     Network.updateUser(that.props.token,v,function (data) {
                        //         Network.loadUsers(that.props.token, that.props.usersLoaded);
                        //     });
                        // }
                        // this.props.close();
                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <FieldArray
                            name="projectIds"
                            render={arrayHelpers => (
                                <div>
                                    {projects.map(it => (
                                        <div key={it.project.id}>
                                            <label>
                                                <input
                                                    name="projectIds"
                                                    type="checkbox"
                                                    value={it.project.id}
                                                    checked={values.projectIds.includes(it.project.id)}
                                                    onChange={e => {
                                                        // console.log(e,values)
                                                        if (!values.projectIds.includes(it.project.id)) {
                                                            console.log("push ",it)
                                                            arrayHelpers.push(it.project.id);
                                                        console.log(values.projectIds,values.projectIds.includes(it.project.id));

                                                        }
                                                        else {
                                                            const idx = values.projectIds.indexOf(it.project.id);
                                                            arrayHelpers.remove(idx);
                                                        }
                                                    }}
                                                />{it.project.client.name}/{it.project.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        />


                        <button type="submit" disabled={isSubmitting}
                                className="btn btn-primary mb-2"
                        >
                            Submit
                        </button>
                    </form>
                )}
            </Formik>);

    }


}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userForEdit: state.user.userForEdit,
        projects: state.user.userProjects

    }
}

const mapDispatchToProps = dispatch => {
    return {

        usersLoaded: (data) => dispatch(actions.user.usersLoaded(data)),
        // tasksLoaded: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.task.tasksLoaded(data))
        // },
        // closeNewActionForm: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.UI.closeActionForm(data))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserProjectsForm);
