import React, {Component, Fragment} from "react";
import './ActionList.css'
import actions from "../actions";
import {connect} from "react-redux";
import Network from "../Network";

class ActionList extends Component {

    constructor(props) {
        super(props);
        this.renderList = this.renderList.bind(this);
        this.clickNew = this.clickNew.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.clickDel = this.clickDel.bind(this);
    }

    render() {
        return (<Fragment>
                <div className={"action-list-grid"}>
                    <div className={"cell cell-head"}>Day</div>
                    <div className={"cell cell-head"}>Project</div>
                    <div className={"cell cell-head"}>Таsk</div>
                    <div className={"cell cell-head"}>Hours</div>
                    <div className={"cell cell-head"}>Description</div>
                    <div className={"cell cell-head"}><img alt={"more"} src="/icons/more.svg" className="edit-icon"/></div>

                </div>
                {this.renderList()}
            </Fragment>
        );
    }

    renderList() {
        // if (this.props.actions.items.length === 0) return null;
        let holidays = {};
        this.props.holidays.items.forEach(function (it) {
            if(holidays[it.day] === undefined){
                holidays[it.day]=it.holiday;
            }
        });

        let dateMap = this.createDateMap();
        let items = this.createItemsMap(dateMap);
        console.log('days')
        console.log(dateMap);
        console.log('items')
        console.log(items);
        let that = this;
        let dark = true;
        return <Fragment>
            {Object.entries(items).map(function (it) {
                dark = !dark;

                return <DateItem
                    key={it[0]}
                    day={it[0]}
                    dayitem={it[1]}
                    dark={dark}
                    newClick={that.clickNew}
                    editClick={that.clickEdit}
                    delClick={that.clickDel}
                    holidays={holidays}
                />;
            })}
        </Fragment>
    }

    componentDidMount() {
        // console.log("MOUNTED Main Content");
        this.loadHolidays();
        this.loadActions();

    }


    loadHolidays(){
        Network.loadHolidays(this.props.token,this.props.holidaysLoaded);
    }
    loadActions() {

        // var endDate = new Date(this.props.end);
        // endDate.setDate(endDate.getDate() + 30);

        Network.loadActions(
            this.props.token,
            this.props.userId,
            this.props.start,
            this.props.end,
            this.props.actionsLoaded
        );
    }


    createDateMap() {


        var startDate = new Date(this.props.start);
        var endDate = new Date(this.props.end);

        let dateMap = {};
        while (true) {
            if (dateMap[startDate.ymd()] === undefined) {
                dateMap[startDate.ymd()] = []
            }

            startDate.setDate(startDate.getDate() + 1);
            if (startDate > endDate) break;
        }
        return dateMap;
    }

    createItemsMap(itemsMap) {
console.log('items map creating',this.props.actions.items)
        this.props.actions.items.forEach(function (el) {
            if (itemsMap[el.day] === undefined) {
                console.log(el.day, 'not exist in map')
                return;
            }
            itemsMap[el.day].push(el);

        });


        return itemsMap;
    }

    clickNew(e) {
        this.props.addAction(e.target.dataset.day);
    }

    clickDel(e) {
        let res = window.confirm('sure?');
        if (!res) return false;
        // this.props.addAction(e.target.dataset.day);
        var url = "/api/action/delete/" + e.target.dataset.id;
        let that = this;
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.props.token
            },
            method: 'DELETE'
        })
            .then(response => response.json())
            .then(function (data) {
                that.loadActions()
            }) // JSON-строка полученная после вызова `response.json()`
            .catch(error => console.error(error))

    }

    clickEdit(e) {
        // console.log('EDIT::: ',e.target.dataset.id)
        let id = e.target.dataset.id;
        this.props.editAction(id);
    }
}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userId: state.authorization.payload.id,
        start: new Date(state.UI.actionsListStart),
        end: new Date(state.UI.actionsListEnd),
        actions: state.action,
        holidays: state.holiday,
        formDay: state.UI.newActionDay,
        showActionForm: state.UI.showActionForm
    }
}

const mapDispatchToProps = dispatch => {
    return {

        actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),
        addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        editAction: (id) => dispatch(actions.UI.editActionForm(id)),
        holidaysLoaded: (data) => dispatch(actions.holiday.holidaysLoaded(data)),

        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
}

function DateItem(props) {
     // console.log('rendering date item',props.day,props);

    // let that = this;
    let items = props.dayitem;
    let day = props.day;
    let realDate = new Date(Date.parse(day));
    let weekDay = realDate.getDay();
    var weekEnd = ' work ';
    if (weekDay === 0 || weekDay === 6) {
        weekEnd = ' week-end '
    }
    let darkRow = props.dark ? ' row-dark ' : ' row-light ';

    if(props.holidays[day] ===true){
        weekEnd = ' week-end '
    }

    if(props.holidays[day] ===false){
        weekEnd = '  work  '
    }
    let dateHours=0;
    let dateHoursClass=''
    items.forEach(function (it) {
        dateHours+= it.spent;
    });
    if(dateHours===0){
        dateHoursClass=' date-empty '
    }
    if(dateHours>0 && dateHours<8){
        dateHoursClass=' date-half '
    }
    if(dateHours===8){
        dateHoursClass=' date-full '
    }
    if(dateHours>8){
        dateHoursClass=' date-over '
    }

    // console.log(day, items);
    let dateClassName = " date" + items.length + " ";
    return <div className={"action-list-grid" + weekEnd + darkRow+dateHoursClass}>

        <div className={"cell date" + dateClassName}>{day}</div>
        {items.map(item => <ListItem key={item.id} item={item} editClick={props.editClick} delClick={props.delClick}/>)}

        <img alt={"new"} data-day={day} src="/icons/new.svg" className="edit-icon new-icon" onClick={props.newClick}/>

    </div>

}

function ListItem(props) {
    let item = props.item;
    return <Fragment>

        <div className={"cell"}>{item.task.project.client.name} / {item.task.project.name}</div>
        <div className={"cell"}>{item.task.name}</div>
        <div className={"cell"}>{item.spent}</div>
        <div className={"cell"}>{item.description}</div>
        <div className={"cell"}>
            <img alt="edit" src="/icons/edit.svg"
                 data-id={item.id}
                 className="edit-icon"
                 onClick={props.editClick}/><img alt="remove" src="/icons/remove.svg"
                                                 data-id={item.id} className="edit-icon" onClick={props.delClick}/>
        </div>
    </Fragment>

}

export default connect(mapStateToProps, mapDispatchToProps)(ActionList);
