import React, {Component} from "react";

import DatePicker, {registerLocale} from "react-datepicker"
import ru from "date-fns/locale/ru"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";


registerLocale("ru", ru); // register it with the name you wa


class DateForm extends Component{

    constructor (props) {
        super(props)
        console.log('date-frm',props.day)
        this.state = {
            startDate: props.day
        };
        this.handleChange = this.handleChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    handleChange(date) {
        this.setState({
            startDate: date
        })
        this.props.chooseHandler(date)

    }

    onFormSubmit(e) {
        e.preventDefault();
        console.log(this.state.startDate)
    }

    render() {


        return (


            <form onSubmit={ this.onFormSubmit }>
                <div className="form-group">
                    <DatePicker
                        selected={ this.props.day }
                        onChange={ this.handleChange }
                        dateFormat="yyyy-MM-dd"
                        inline

                        showPreviousMonths
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="day"
                        todayButton="Today"
                        locale="ru"
                    />
                </div>
            </form>

        );
    }
}

export default DateForm