import React, {Component, Fragment} from "react";
import DatePanel from "./navigation/DatePanel";
import {connect} from "react-redux";
import Network from "../Network";
import actions from "../actions";
import './ProjectFilterBody.css'

import './reports.css'

class ProjectFilterBody extends Component {

    render() {
        let report = null;
        if (this.props.reportData) {
            report = this.createReport();
        }
        console.log("project filter", this.props);
        let name = '...loading...';
        if (this.props.reportProject) {
            name = this.props.reportProject.client.name + ' ⇒ ' + this.props.reportProject.name;
        }
        return (
            <div className={"project-filter-report"}>

                <div className={"row"} style={{height: "35px"}}></div>
                <DatePanel>
                    <h1>{name} </h1>
                </DatePanel>
                {report}

                <div className={"row"} style={{height: "35px"}}></div>
            </div>
        );
    }

    loadData() {
        Network.loadOneProjects(this.props.token, this.props.projectFilterId, this.props.projectLoaded);
        Network.loadOneProjectReport(this.props.token, this.props.projectFilterId, this.props.startDate, this.props.endDate, this.props.reportLoaded)
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.projectFilterId != prevProps.projectFilterId ||
            this.props.startDate.ymd() != prevProps.startDate.ymd() || this.props.endDate.ymd() != prevProps.endDate.ymd()
        ) {
            this.loadData()
        }

    }

    createReport() {
        /*
clientId: 15
clientName: "Bosch"
day: "2020-04-27"
description: "testing performance"
id: 1
projectId: 20
projectName: "B2B E-Commerce"
spent: 8
taskId: 1
taskName: "optimize hybris"
userId: 9
*/
        let sum = 0;
        let tasks = {}
        let days = {}
        this.props.reportData.forEach(function (it) {
            sum = sum + it.spent;
            if (tasks[it.taskId] === undefined) {
                tasks[it.taskId] = {};
                tasks[it.taskId]["name"] = it.taskName;
                tasks[it.taskId]["spent"] = 0;
            }
            tasks[it.taskId]["spent"] = tasks[it.taskId]["spent"] + it.spent;

            if (days[it.day] === undefined) {
                days[it.day] = {"day": it.day, "spent": 0};
            }
            days[it.day]["spent"] = days[it.day]["spent"] + it.spent
        })
        console.log('report is', sum, tasks, days);
        return (<div>
            <Summary hours={sum}/>
            <hr/>
            <TaskReport tasks={tasks}/>
            <hr/>
            <DayReport days={days}/>
            <hr/>
            <FullReport data={this.props.reportData}/>
            <hr/>
        </div>);


        // return null;
    }
}

function Summary(props) {
    return <h2>Totlal {props.hours} hours</h2>
}

function TaskReport(props) {
    console.log(props.tasks)
    let lst = [];
    for (let k in props.tasks) {
        lst.push(props.tasks[k]);
    }
    return (<div>
        <h3>Time by task</h3>
        {lst.map(function (it) {
            return (<div className={"p-report-task-grid"}>
                <div></div>
                <div className={"cell"}>{it.name}</div>
                <div className={"cell"}>{it.spent}</div>
                <div></div>
            </div>)
        })}
    </div>)

}

function DayReport(props) {

    let lst = [];
    for (let k in props.days) {
        lst.push(props.days[k]);
    }

    return (
        <div>
            <h3>Time by day</h3>
            {lst.map(function (it) {
                return (<div className={"p-report-day-grid"}>
                    <div></div>
                    <div className={"cell"}>{it.day}</div>
                    <div className={"cell"}>{it.spent}</div>
                    <div></div>
                </div>);
            })}
        </div>
    );
}

function FullReport(props) {
    let lst = props.data;

    return (
        <div>
            <h3>Detailed report</h3>
            {lst.map(function (it) {
                return (<div className={"p-report-full-grid"}>
                    <div></div>

                    <div className={"cell"}>{it.day}</div>
                    <div className={"cell"}>{it.taskName}</div>
                    <div className={"cell cell-description"}>{it.description}</div>
                    <div className={"cell"}>{it.spent}</div>
                    <div></div>
                </div>);
            })}
        </div>
    );

}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        // loginDisplay: state.loginForm.visible,
        startDate: new Date(state.UI.actionsListStart),
        endDate: new Date(state.UI.actionsListEnd),
        projectFilterId: state.UI.projectFilterId,
        reportProject: state.project.projectForEdit,
        reportData: state.report.reportOneProject,
    }
};

const mapDispatchToProps = dispatch => {
    return {

        projectLoaded: function (data) {
            return dispatch(actions.project.loadedForEdit(data))
        },
        reportLoaded: (data) => dispatch(actions.report.reportOneProjectLoaded(data)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ProjectFilterBody);