import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import actions from "../actions";


import './ProjectsBody.css'
import Network from "../Network";

class ProjectsBody extends Component {


    constructor(props, context) {
        super(props, context);
        // this.clickHeader = this.clickHeader.bind(this);
        this.clickAdd = this.clickAdd.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.clickRemove = this.clickRemove.bind(this);
    }

    clickRemove(e) {
        let id = e.target.dataset.id;
        let that = this;
        console.log(e.target, e.target.dataset);
        if (!window.confirm("Sure?")) {
            return false;
        }
        Network.projectRemove(this.props.token, id, function () {
            Network.loadProjects(that.props.token, that.props.projectsLoaded, error => console.error(error));

        });
    }

    clickEdit(e) {
        let that = this;
        const success = function (data) {
            that.props.projectLoaded(data);
            setTimeout(that.props.editProjectShow, 200);
        };
        Network.loadOneProjects(this.props.token, e.target.dataset.id, success)
        // this.props.editProjectShow({id:e.target.dataset.id});
    }

    clickAdd(e) {
        this.props.addProjectShow();
    }

    render() {
        if (this.props.userInfo.priveleged === "false") {
            return <div>
                <div className={"row"} style={{height: "35px"}}></div>
                <h1>Projects</h1>
                <h2> Access denied </h2>
            </div>;
        }
        function sortProjects(i1, i2) {
            if (i1.client.name > i2.client.name) return 1;
            if (i1.client.name < i2.client.name) return -1;
            if (i1.name > i2.name) return 1;
            if (i1.name < i2.name) return -1;
            return 0;
        }

        // this.props.task.items.sort(sortTask);
        this.props.project.items.sort(sortProjects)

        return <div>
            <div className={"row"} style={{height: "35px"}}></div>

            <h1>Projects</h1>
            <button type="button" className="btn btn-primary" onClick={this.clickAdd}>Add project</button>
            <div>{this.props.project.items.map(it => {
                return <Fragment key={it.id}>
                    <div className={"client-grid"}>
                        <div
                            className={"cell"}
                            data-id={it.id}
                        >{it.client.name} / {it.name}</div>
                        <div className={"cell cell-edit"} data-id={it.id} data-client={it.client.id}
                             onClick={this.clickEdit}></div>
                        <div className={"cell cell-del"} data-id={it.id} onClick={this.clickRemove}></div>
                    </div>
                </Fragment>
            })}</div>

        </div>
    }

    componentDidMount() {
        if (this.props.userInfo.priveleged === "false") {
            return ;
        }
        let that = this;
        const success = function (data) {
            console.log(data);
            console.log('Projects mount fetched');
            that.props.projectsLoaded(data);
        };
        Network.loadProjects(this.props.token, success, error => console.error(error));
    }

}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userInfo: state.authorization.payload,

        // task: state.task,
        client: state.client,
        project: state.project,
        // userId: state.authorization.payload.id,
        // start: new Date(state.UI.actionsListStart),
        // end: new Date(state.UI.actionsListEnd),
        // actions: state.action,
        // formDay: state.UI.newActionDay,
        // showActionForm: state.UI.showActionForm
    }
};

const mapDispatchToProps = dispatch => {
    return {

        tasksLoaded: (data) => {
            // console.log('tasks here');
            return dispatch(actions.task.tasksLoaded(data))
        },
        projectsLoaded: (data) => {
            console.log('Projects is here');
            return dispatch(actions.project.projectsLoaded(data));
        },
        projectLoaded: function (data) {
            return dispatch(actions.project.loadedForEdit(data))
        },
        addProjectShow: function () {
            return dispatch(actions.UI.showAddProject());
        },
        editProjectShow: function () {
            return dispatch(actions.UI.showEditProject());
        },
        editProjectClose: function () {
            return dispatch(actions.UI.closeEditProject());
        }
        //
        // actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),
        // addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        // editAction: (id) => dispatch(actions.UI.editActionForm(id))
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ProjectsBody);