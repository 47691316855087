import React, {Component} from "react";
import {connect} from 'react-redux';
import ActionList from "./ActionList";
import DatePanel from "./navigation/DatePanel";


class ActionsBody extends Component {


    render() {
        return <div>
            <div className={"row"} style={{height: "35px"}}></div>
            <DatePanel><h1>T &nbsp;&nbsp;I &nbsp;&nbsp;M &nbsp;&nbsp;E &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L &nbsp;&nbsp;I &nbsp;&nbsp;N &nbsp;&nbsp;E</h1></DatePanel>
            <ActionList key={this.props.start + "/" + this.props.end}/>
        </div>;
    }


}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        // userId: state.authorization.payload.id,
        start: new Date(state.UI.actionsListStart),
        end: new Date(state.UI.actionsListEnd),
        // actions: state.action,
        // formDay: state.UI.newActionDay,
        // showActionForm: state.UI.showActionForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //
        // actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),
        // addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        // editAction: (id) => dispatch(actions.UI.editActionForm(id))
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ActionsBody);
