import React, {Component} from "react";
import {connect} from 'react-redux';
import Network from "../Network";
import actions from "../actions";
import './HolidaysBody.css'


class HolidaysBody extends Component {


    constructor(props, context) {
        super(props, context);
        this.clickAdd=this.clickAdd.bind(this);
        this.clickRemove=this.clickRemove.bind(this);
    }

    clickAdd(e) {
        this.props.addHolidayShow();
    }
    clickRemove(e) {
        let id = e.target.dataset.id;
        let that = this;
        console.log(e.target, e.target.dataset);
        if (!window.confirm("Sure?")) {
            return false;
        }
        Network.removeHoliday(this.props.token, id, function () {
            Network.loadHolidays(that.props.token, that.props.holidaysLoaded);

        });
    }
    render() {
        if (this.props.userInfo.priveleged === "false") {
            return <div>
                <div className={"row"} style={{height: "35px"}}></div>
                <h1>Holidays</h1>
                <h2> Access denied </h2>
            </div>;
        }



        return <div><div className={"row"} style={{height:"35px"}}></div>
            <h1>Holidays</h1>
         <button type="button" className="btn btn-primary" onClick={this.clickAdd}>Add holiday</button>
            <div className={"row"} style={{height:"35px"}}></div>
            <div className={"holiday-grid"} style={{background:'#888',padding:'5px'}}>
                <div
                    className={"cell"}

                >Date </div>
                <div
                    className={"cell"}

                >Comment</div>

                <div className={"cell "}
                >is Holiday</div>
                {/*<div className={"cell cell-edit"} ></div>*/}
                <div className={"cell cell-del"}></div>

            </div>
            {this.props.holiday.items.map(it=>{
                return <>
                <div className={"holiday-grid"}>
                    <div
                        className={"cell"}
                        data-id={it.id}
                    >{it.day} </div>
                    <div
                        className={"cell"}
                        data-id={it.id}
                    >{it.name} </div>

                    <div className={"cell cell-holiday is-holiday-"+it.holiday} data-id={it.id}
                        ></div>
                    {/*<div className={"cell cell-edit"} data-id={it.id}*/}
                    {/*     onClick={this.clickEdit}></div>*/}
                    <div className={"cell cell-del"} data-id={it.id} onClick={this.clickRemove}></div>

                </div>

                   </>
            })}
        </div>
    }


    componentDidMount() {
        if (this.props.userInfo.priveleged === "false") {
            return ;
        }
        let that = this;
        const success = function (data) {
            console.log(data);
            console.log('Holidays mount fetched');
            that.props.holidaysLoaded(data);
        };
        Network.loadHolidays(this.props.token, success);
    }
}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        holiday: state.holiday,
        userInfo: state.authorization.payload,
        // userId: state.authorization.payload.id,
        // start: new Date(state.UI.actionsListStart),
        // end: new Date(state.UI.actionsListEnd),
        // actions: state.action,
        // formDay: state.UI.newActionDay,
        // showActionForm: state.UI.showActionForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //
        holidaysLoaded: (data) => dispatch(actions.holiday.holidaysLoaded(data)),
        addHolidayShow: () => dispatch(actions.UI.showAddHolidayPopup()),

        // addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        // editAction: (id) => dispatch(actions.UI.editActionForm(id))
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(HolidaysBody);