import React, {Component} from 'react';
import actions from "../../actions";
import {connect} from "react-redux";
import ActionForm from "./ActionForm";



class NewActionForm extends Component {


    render() {

        return (
            <div className="modal" tabIndex="-1" role="dialog" style={{display: "block",}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter your data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={this.props.closeNewActionForm}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">


                            <ActionForm
                                userId={this.props.userId}
                                spent={0}
                                description={""}
                                date={this.props.selectedDay}
                                taskId={0}
                                close={this.props.closeNewActionForm}
                            />
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn btn-danger mb-2" data-dismiss="modal"
                                    onClick={this.props.closeNewActionForm}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.loadTasks();

    }

    loadTasks() {
        let url = "/api/task/list";
        console.log('start fetch task list;')
        let that = this;
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.props.token
            }
        })
            .then(response => response.json())
            .then(function (data) {
                console.log(data);
                console.log('tasks fetched');
                that.props.tasksLoaded(data);
            }) // JSON-строка полученная после вызова `response.json()`
            .catch(error => console.error(error))
    }


}



const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userId: state.authorization.payload.id,
        project: state.project,
        start: new Date(state.UI.actionsListStart),
        end: new Date(state.UI.actionsListEnd),
        actions: state.action,
        task: state.task,
        selectedDay: new Date(Date.parse(state.UI.newActionDay))
    }
}

const mapDispatchToProps = dispatch => {
    return {

        actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),

        tasksLoaded: (data) => {
            // console.log('tasks here');
            return dispatch(actions.task.tasksLoaded(data))
        },
        closeNewActionForm: (data) => {
            // console.log('tasks here');
            return dispatch(actions.UI.closeActionForm(data))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NewActionForm);