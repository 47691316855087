import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import actions from "../actions";
import Network from "../Network";

import './TasksBody.css'

class TasksBody extends Component {


    constructor(props, context) {
        super(props, context);
        this.clickAdd = this.clickAdd.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.clickRemove = this.clickRemove.bind(this);
    }

    clickAdd(e) {
        this.props.addTaskShow();
    }

    clickEdit(e) {
        let that = this;
        const success = function (data) {
            that.props.taskLoaded(data);
            setTimeout(that.props.editTaskShow, 200);
        };
        Network.loadOneTask(this.props.token, e.target.dataset.id, success)

    }

    clickRemove(e) {
        let id = e.target.dataset.id;
        let that = this;
        console.log(e.target, e.target.dataset);
        if (!window.confirm("Sure?")) {
            return false;
        }
        Network.taskRemove(this.props.token, id, function () {
            Network.loadTasks(that.props.token, that.props.tasksLoaded);

        });
    }
    render() {
        if (this.props.userInfo.priveleged === "false") {
            return <div>
                <div className={"row"} style={{height: "35px"}}></div>
                <h1>Task</h1>
                <h2> Access denied </h2>
            </div>;
        }
        function sorter(i1, i2) {
            if (i1.project.client.name < i2.project.client.name) return -1;
            if (i1.project.client.name > i2.project.client.name) return 1;
            if (i1.project.name < i2.project.name) return -1;
            if (i1.project.name > i2.project.name) return 1;
            if (i1.name > i2.name) return 1;
            if (i1.name < i2.name) return -1;
            return 0;
        }


        this.props.task.items.sort(sorter);

        return <div>
            <div className={"row"} style={{height: "35px"}}></div>

            <h1>Tasks</h1>
            <button type="button" className="btn btn-primary" onClick={this.clickAdd}>Add task</button>
            <div>{this.props.task.items.map(it => {
                return <Fragment key={it.id}>
                    <div className={"task-grid"}>
                        <div
                            className={"cell"}
                            data-id={it.id}
                        >{it.project.client.name} </div>
                        <div
                            className={"cell"}
                            data-id={it.id}
                        >{it.project.name} </div>
                        <div
                            className={"cell"}
                            data-id={it.id}
                        >{it.name}</div>
                        <div className={"cell cell-edit"} data-id={it.id}
                             onClick={this.clickEdit}></div>
                        <div className={"cell cell-del"} data-id={it.id} onClick={this.clickRemove}></div>
                    </div>
                </Fragment>
            })}</div>

        </div>
    }

    componentDidMount() {
        if (this.props.userInfo.priveleged === "false") {
            return ;
        }
        Network.loadTasks(this.props.token, this.props.dataLoaded);
    }


}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        task: state.task,
        userInfo: state.authorization.payload,
        // start: new Date(state.UI.actionsListStart),
        // end: new Date(state.UI.actionsListEnd),
        // actions: state.action,
        // formDay: state.UI.newActionDay,
        // showActionForm: state.UI.showActionForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //
        dataLoaded: (data) => dispatch(actions.task.tasksLoaded(data)),
        addTaskShow: () => dispatch(actions.UI.showAddTaskPopup()),
        editTaskShow: ()=> dispatch(actions.UI.showEditTaskPopup()),
        taskLoaded: (data)=>dispatch(actions.task.loadedForEdit(data)),
        tasksLoaded: (data) => {
            console.log('tasks is reloaded');
            return dispatch(actions.task.tasksLoaded(data));
        },
        // addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        // editAction: (id) => dispatch(actions.UI.editActionForm(id))
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(TasksBody);