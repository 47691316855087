import React, {Component} from "react";
import {connect} from 'react-redux';
import NavigationLink from './NavigationLink'
import actions from "../../actions";

class NavigationBar extends Component {


    constructor(props, context) {
        super(props, context);
        this.clickHandler = this.clickHandler.bind(this);
    }

    logoutClick(e) {
        // alert('me')
        localStorage.setItem('state','{}');
        window.location.reload();
    }

    render() {
        let priv = false;
        console.log('AUTH', this.props)
        if(this.props.auth !=undefined
            && this.props.auth.payload != undefined
            && this.props.auth.payload.priveleged != undefined
            && this.props.auth.payload.priveleged==="true"){
            priv = true;
        }

        return (<nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <a className="navbar-brand" href="#">TimeTruck</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav mr-auto">


                    <NavigationLink disabled={!this.props.authorized||!priv}
                                    active={this.props.tab === 'holidays'}
                                    id={"holidays"}
                                    onClick={this.clickHandler}
                                    name="Holidays"/>


                    <NavigationLink disabled={!this.props.authorized||!priv}
                                    active={this.props.tab === 'users'}
                                    id={"users"}
                                    onClick={this.clickHandler}
                                    name="Users"/>

                    <NavigationLink disabled={!this.props.authorized||!priv}
                                    active={this.props.tab === 'clients'}
                                    id={"clients"}
                                    onClick={this.clickHandler}
                                    name="Clients"/>

                    <NavigationLink disabled={!this.props.authorized||!priv}
                                    active={this.props.tab === 'projects'}
                                    id={"projects"}
                                    onClick={this.clickHandler}
                                    name="Projects"/>

                    <NavigationLink disabled={!this.props.authorized||!priv}
                                    active={this.props.tab === 'tasks'}
                                    id={"tasks"}
                                    onClick={this.clickHandler}
                                    name="Tasks"/>

                    <NavigationLink disabled={!this.props.authorized}
                                    active={this.props.tab === 'actions'}
                                    id={"actions"}
                                    onClick={this.clickHandler}
                                    name="TimeLine"/>

                </ul>

                <div className="mt-2 mt-md-0">
                    <ul className="navbar-nav mr-auto">

                        <NavigationLink disabled={this.props.authorized} name="Please Log in"
                                        onClick={this.props.loginClick}/>

                        <NavigationLink disabled={!this.props.authorized} name="Log Out" onClick={this.logoutClick}/>

                    </ul>
                </div>

            </div>
        </nav>);
    }

    clickHandler(id) {
        // alert("clicked: "+id);
        this.props.tabActivate(id);
    }
}


const mapStateToProps = function (state) {

    return {
        authorized: state.authorization.token != null,
        loginDisplay: state.loginForm.visible,
        tab: state.UI.activeTab,
        auth: state.authorization

        // start: new Date(state.UI.actionsListStart),
        // end: new Date(state.UI.actionsListEnd),
    }
}

const mapDispatchToProps = dispatch => {
    return {

        tabActivate: function (tabId) {
            dispatch(actions.UI.setActiveTab(tabId))
        },
        loginClick: function () {
            dispatch(actions.loginFormShow.displayLoginForm())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);