import {Field, Formik} from "formik";
import React, {Component, Fragment} from "react";
import actions from "../../actions";
import {connect} from "react-redux";
import Network from "../../Network";

class TaskForm extends Component{
    render(){
        return (
            <Formik
                initialValues={{
                    projectId: this.props.projectId,
                    name: this.props.name,
                    id: this.props.id
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        let v = Object.assign({}, values);
                        // console.log("VALUES")
                        // console.log(values)
                        // console.error(v);

                        let that = this;

                        console.log('values:',v);
                        setSubmitting(false);
                        if(v.id==0) {
                            Network.createTask(that.props.token, v, function (data) {
                                Network.loadTasks(that.props.token, that.props.tasksLoaded);
                            });
                        }else{
                            Network.updateTask(that.props.token,v,function (data) {
                                Network.loadTasks(that.props.token, that.props.tasksLoaded);
                            });
                        }
                        this.props.close();
                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>



                        <div className={"form-group"}>
                            <label
                                htmlFor="projectId" className={"col-sm-9 col-form-label"}>Project</label>
                            <Field name="projectId" as="select" className="my-select form-control">
                                <option value="0">---------</option>
                                {this.renderProjects()}
                            </Field>
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor="name" className={"col-sm-9 col-form-label"}>
                                ProjectName
                            </label>
                            <input className={"form-control"}
                                   type="text"
                                   name="name"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.name}
                            />

                        </div>



                        <button type="submit" disabled={isSubmitting}
                                className="btn btn-primary mb-2"
                        >
                            Submit
                        </button>
                    </form>
                )}
            </Formik>);
    }
    renderProjects() {
        console.log('rendering project dropdown', this.props.projects)

        function sortProjects(i1, i2) {
            if(i1.client.name > i2.client.name) return 1;
            if(i1.client.name < i2.client.name) return -1;
            if (i1.name > i2.name) return 1;
            if (i1.name < i2.name) return -1;
            return 0;
        }

        this.props.projects.items.sort(sortProjects);

        return <Fragment>
            {this.props.projects.items.map(it => <option key={it.id}
                                                        value={it.id}>{it.client.name}/{it.name}</option>)}
        </Fragment>
    }

}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        projects: state.project
    }
};

const mapDispatchToProps = dispatch => {
    return {
        tasksLoaded: (data) => {
            console.log('tasks is reloaded');
            return dispatch(actions.task.tasksLoaded(data));
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
