const actions = {

    // task: {
    //     addTask: () => ({
    //         type: 'ADD_TASK'
    //     }),
    //     tasksLoaded: (data) => ({
    //         type: 'TASKS_LOADED', data
    //     }),
    //     deleteTask: (data) => ({
    //         type: 'DELETE_TASK', data
    //     })
    // },
    UI:{
        setDate: function(data) {
            let t = data.dateType === 'end' ? 'SET_END_ACTION_LIST_DATE' : 'SET_START_ACTION_LIST_DATE';
            return {type: t, data: data.date};
        },
        setActiveTab: function(tabId){
            return {type:"SET_ACTIVE_TAB",data:tabId};
        },
        openActionListDateForm: (data) => ({
            type: 'ACTION_LIST_DATE_SHOW', data
        }),
        closeActionListDateForm: (data) => ({
            type: 'ACTION_LIST_DATE_CLOSE', data:{}
        }),
        createActionForm: (day) => ({
            type: 'ACTIONS_CREATE_FORM', data:day
        }),
        editActionForm: (id) =>({
            type: 'ACTIONS_EDIT_FORM', data:id
        }),
        loadedActionForEdit: (data) =>({
            type: 'ACTION_FOR_EDIT_LOADED',data:data
        }),
        closeActionForm: (d) => ({
            type: 'ACTIONS_CREATE_FORM_CLOSE', data:{}
        }),
        closeEditActionForm: () => ({
            type: 'ACTIONS_EDIT_FORM_CLOSE', data:{}
        }),
        showAddProject: ()=> ({
            type: 'PROJECT_ADD_SHOW'
        }),
        closeAddProject: ()=> ({
            type: 'PROJECT_ADD_CLOSE'
        }),
        showEditProject: (data) => ({
            type: 'PROJECT_EDIT_SHOW'
        }),
        closeEditProject: () => ({
            type: 'PROJECT_EDIT_CLOSE'
        }),
        showAddTaskPopup: ()=>({ type: 'TASK_ADD_SHOW'}),
        closeAddTaskPopup: ()=>({ type: 'TASK_ADD_CLOSE'}),
        showEditTaskPopup: ()=>({type: 'TASK_EDIT_SHOW'}),
        closeEditTaskPopup: ()=>({type: 'TASK_EDIT_CLOSE'}),
        showAddHolidayPopup: ()=>({type:'HOLIDAY_ADD_SHOW'}),
        closeAddHolidayPopup: ()=>({type:'HOLIDAY_ADD_CLOSE'}),
        showEditHolidayPopup: ()=>({type:'HOLIDAY_EDIT_SHOW'}),
        closeEditHolidayPopup: ()=>({type:'HOLIDAY_EDIT_CLOSE'}),
        showAddUserPopup: ()=>({type:'USER_ADD_SHOW'}),
        closeAddUserPopup: ()=>({type:'USER_ADD_CLOSE'}),
        showEditUserPopup: ()=>({type:'USER_EDIT_SHOW'}),
        closeEditUserPopup: ()=>({type:'USER_EDIT_CLOSE'}),
        showUserProjectsPopup: ()=>({type:'USER_PROJECTS_SHOW'}),
        closeUserProjectsPopup: ()=>({type:'USER_PROJECTS_CLOSE'}),
        filterProject: (id)=>({type:'PROJECT_FILTER',data:id}),
    },
    task:{
        tasksLoaded: function(data){
            console.log('int the task action');
            console.log(data);
            return {
            type: 'TASKS_LOADED', data
        };
        },
        loadedForEdit: (data) => ({
            type: 'TASK_EDIT_LOADED', data
        })
    },
    client:{

        clientsLoaded: function(data){
            console.log('in the client loaded action');
            console.log(data);
            return {
                type: 'CLIENTS_LOADED', data
            };
        }
    },
    action:{
        actionsLoaded: (data) => ({
            type: 'ACTIONS_LOADED', data
        })
    },
    project: {
        projectsLoaded: (data) => ({
            type: 'PROJECTS_LOADED', data
        }),
        loadedForEdit: (data) => ({
            type: 'PROJECT_EDIT_LOADED', data
        })
    } ,
    holiday: {
        holidaysLoaded: (data) => ({
            type: 'HOLIDAYS_LOADED', data
        })
    },
    user: {
        usersLoaded: (data) => ({
            type: 'USERS_LOADED', data
        }),

        loadedForEdit: (data) => ({
            type: 'USER_EDIT_LOADED', data
        }),
        projectsLoaded: (data,user) =>({
            type: 'USER_PROJECTS_LOADED',data,user
        })
    },
    loginFormShow: {
        displayLoginForm: ()=>({type:'LOGIN_SHOW'}),
        hideLoginForm: ()=>({type:'LOGIN_HIDE'}),
    },
    report:{
        reportByProjectsLoaded: (data)=>({type:'REPORT_BYPROJECT_LOADED',data}),
        reportOneProjectLoaded: (data)=>({type:'REPORT_ONE_PROJECT_LOADED',data}),
    }

};
export default actions
