import React, {Component} from "react";
import {connect} from 'react-redux';
import actions from "../../actions";

import TaskForm from "./TaskForm";


class EditTaskFormPopup extends Component{

    render() {
        if(!this.props.show){
            return null;
        }
        let show = {display: 'block'};

        return <div className="modal" tabIndex="-1" role="dialog" style={show}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add new Task</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={this.props.closeWindow}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        new task add
                        <TaskForm
                            projectId={this.props.taskForEdit.project.id}
                            id={this.props.taskForEdit.id}
                            name={this.props.taskForEdit.name}
                            close={this.props.closeWindow}
                        />

                    </div>
                    <div className="modal-footer">

                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={this.props.closeWindow}>Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,

        show: state.UI.showEditTaskForm,
        taskForEdit: state.task.taskForEdit,
        // userId: state.authorization.payload.id,
        // start: new Date(state.UI.actionsListStart),
        // end: new Date(state.UI.actionsListEnd),
        // actions: state.action,
        // formDay: state.UI.newActionDay,
        // showActionForm: state.UI.showActionForm
    }
};

const mapDispatchToProps = dispatch => {
    return {

        closeWindow: function () {
            dispatch(actions.UI.closeEditTaskPopup());
        }
        //
        // actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),
        // addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        // editAction: (id) => dispatch(actions.UI.editActionForm(id))
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditTaskFormPopup);
