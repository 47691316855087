import React, {Component} from "react";
import LeftNavigation from "../navigation/LeftNavigation";
import {connect} from "react-redux";
import ActionsBody from "../ActionsBody";
import MarketingContent from "../MarketingContent";
import ProjectsBody from "../ProjectsBody";
import UsersBody from "../UsersBody";
import TasksBody from "../TasksBody";
import ClientsBody from "../ClientsBody";
import HolidaysBody from "../HolidaysBody";
import ReportByProjectBody from "../ReportByProjectBody";
import ProjectFilterBody from "../ProjectFilterBody";


class MainLayout extends Component {

    render() {
        console.log('main layout props', this.props);

        let mainSection = '';
        if (!this.props.authorized) {
            mainSection = <MarketingContent/>
        } else {
            if(this.props.tab==='actions') {
                mainSection = <ActionsBody/>
            }
            if(this.props.tab==='projects'){
                mainSection = <ProjectsBody/>
            }
            if(this.props.tab==='users'){
                mainSection = <UsersBody/>
            }
            if(this.props.tab==='tasks'){
                mainSection = <TasksBody/>
            }
            if(this.props.tab==='clients'){
                mainSection = <ClientsBody/>
            }

            if(this.props.tab==='holidays'){
                mainSection = <HolidaysBody/>
            }
            if(this.props.tab==='byproject'){
                mainSection = <ReportByProjectBody/>
            }


            if(this.props.tab==='projectFilter'){
                mainSection = <ProjectFilterBody/>
            }

        }

        return (<div className="container-fluid App-body">

            <div className="row">

                <LeftNavigation/>

                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">

                    {mainSection}
                </main>
            </div>
        </div>);
    }

}


const mapStateToProps = function (state) {

    return {
        authorized: state.authorization.token != null,
        tab: state.UI.activeTab
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);