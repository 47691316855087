import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import actions from "../actions";
import Network from "../Network";
import './UsersBody.css'


class UsersBody extends Component {

    constructor(props, context) {
        super(props, context);
        this.clickAdd = this.clickAdd.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.clickEditProjects = this.clickEditProjects.bind(this);
        this.clickRemove = this.clickRemove.bind(this);
    }


    clickEditProjects(e){
        let userId = parseInt(e.target.dataset.id)
        console.log("edit popup for UserProjects for user: " + userId);
        let that = this;
        let user=null;
        // find user
        this.props.user.items.forEach(function(it){
            if(parseInt(it.id)==userId){
                user = it;
            }
        });
        const success = function (data) {
            console.log("user projects for edit loaded",data);
            that.props.projectSetLoaded(data,user);
            setTimeout(that.props.editUserProjectsShow, 200);
        };
        Network.loadProjectsForUser(this.props.token, userId, success)

    }

    clickEdit(e) {
        console.log("usre edit clicked");
        let that = this;
        const success = function (data) {
            console.log("user for edit loaded",data);
            that.props.userLoaded(data);
            setTimeout(that.props.editUserShow, 200);
        };
        Network.loadOneUser(this.props.token, e.target.dataset.id, success)

    }

    clickRemove(e) {
        alert("will not implement for some reasons")

    }

    clickAdd(e) {
        this.props.addUserShow();
    }

    render() {
        console.log(this.props.userInfo)
        if (this.props.userInfo.priveleged === "true") {

            return <div>
                <div className={"row"} style={{height: "35px"}}></div>
                <h1>Users</h1>
                <button type="button" className="btn btn-primary" onClick={this.clickAdd}>Add user</button>

                <div>{this.props.user.items.map(it => {
                    return <Fragment key={it.id}>
                        <div className={"user-grid"}>
                            <div
                                className={"cell"}
                                data-id={it.id}
                            >{it.name} </div>
                            <div
                                className={"cell"}
                                data-id={it.id}
                            >{it.email} </div>
                            <div
                                className={"cell"}
                                data-id={it.id}
                            >{it.priveleged ? "admin" : "user"}</div>
                            <div className={"cell cell-edit"} data-id={it.id}
                                 onClick={this.clickEdit}></div>
                            <div className={"cell cell-edit cell-project"} data-id={it.id}
                                                                     onClick={this.clickEditProjects}></div>
                            {/*<div className={"cell cell-del"} data-id={it.id} onClick={this.clickRemove}></div>*/}
                        </div>
                    </Fragment>
                })}</div>
            </div>;
        } else {

            return <div>
                <div className={"row"} style={{height: "35px"}}></div>
                <h1>Users</h1>
                <h2> Access denied </h2>
            </div>;
        }
    }

    componentDidMount() {
        if (this.props.userInfo.priveleged === "false") {
            return ;
        }
        Network.loadUsers(this.props.token, this.props.usersLoaded);
    }
}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userInfo: state.authorization.payload,
        user: state.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        usersLoaded: (data) => dispatch(actions.user.usersLoaded(data)),
        addUserShow: () => dispatch(actions.UI.showAddUserPopup()),
        editUserShow: ()=> dispatch(actions.UI.showEditUserPopup()),
        userLoaded: (data)=>dispatch(actions.user.loadedForEdit(data)),
        projectSetLoaded:  (data,user)=>dispatch(actions.user.projectsLoaded(data,user)),
        editUserProjectsShow: ()=> dispatch(actions.UI.showUserProjectsPopup()),
        //
        // actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),
        // addAction: (day) => dispatch(actions.UI.createActionForm(day)),
        // editAction: (id) => dispatch(actions.UI.editActionForm(id))
        // loginClick: function() {
        //
        //     console.log("login show")
        //     dispatch({type:"LOGIN_SHOW"})
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UsersBody);