import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

function loginForm(state = {"visible": false}, action) {
    if (action.type === "LOGIN_SHOW") {
        return {"visible": true};
    }
    if (action.type === "LOGIN_HIDE") {
        return {"visible": false};
    }
    if (action.type === "LOGIN_TOGGLE") {
        return {"visible": !state.visible};
    }
    return state;
}

function authorization(state = {"token": null}, action) {
    console.log(state)
    if (action.type === "AUTHORIZE") {
        var payload = JSON.parse(atob(action.token.split('.')[1]));
        var start = new Date(payload.iat * 1000);
        var end = new Date(payload.exp * 1000);
        return {token: action.token, payload, dates: {start, end}};
    }
    // console.log('init')
    console.log(state);
    return state;
}

var d = new Date();
d.setDate(d.getDate() - 30);
var dd = new Date();
dd.setDate(dd.getDate() + 10);


const UI = (state = {
    showActionForm: false,
    newActionDay: '2009-01-01',
    actionsListStart: d,
    actionsListEnd: dd,
    activeTab: 'actions' /// actions,projects,users,clients
}, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_TAB':
            return Object.assign({}, state, {activeTab: action.data});
        case 'ACTIONS_CREATE_FORM':
            //alert(action.data);
            return Object.assign({}, state, {showActionForm: true}, {newActionDay: action.data});
        // return state;
        case 'ACTIONS_EDIT_FORM':
            return Object.assign({}, state, {showEditActionForm: true}, {actionEditId: action.data});
        case 'ACTIONS_CREATE_FORM_CLOSE':
            // alert(action.data);
            return Object.assign({}, state, {showActionForm: false});
        case 'ACTIONS_EDIT_FORM_CLOSE':
            // alert(action.data);
            return Object.assign({}, state, {showEditActionForm: false, editableAction: undefined});
        // return state;
        case 'ACTION_FOR_EDIT_LOADED':
            console.log('action loaded', action.data)
            return Object.assign({}, state, {editableAction: action.data});
        case 'ACTION_LIST_DATE_SHOW':
            console.log('show start date', action.data)
            return Object.assign({}, state, {actionListDateFormShow: true, actionListDateType: action.data});
        case 'ACTION_LIST_DATE_CLOSE':
            console.log('close start date', action.data)
            return Object.assign({}, state, {actionListDateFormShow: false});
        case 'SET_START_ACTION_LIST_DATE':
            return Object.assign({}, state, {actionsListStart: action.data, actionListDateFormShow: false});
        case 'SET_END_ACTION_LIST_DATE':
            return Object.assign({}, state, {actionsListEnd: action.data, actionListDateFormShow: false});
        case 'PROJECT_ADD_SHOW':
            return Object.assign({}, state, {showAddProjectForm: true});
        case 'PROJECT_ADD_CLOSE':
            return Object.assign({}, state, {showAddProjectForm: false});
        case 'PROJECT_EDIT_SHOW':
            return Object.assign({}, state, {showEditProjectForm: true});
        case 'PROJECT_EDIT_CLOSE':
            return Object.assign({}, state, {showEditProjectForm: false});
        case 'TASK_ADD_SHOW':
            return Object.assign({}, state, {showAddTaskForm: true});
        case 'TASK_ADD_CLOSE':
            return Object.assign({}, state, {showAddTaskForm: false});

        case 'TASK_EDIT_SHOW':
            return Object.assign({}, state, {showEditTaskForm: true});
        case 'TASK_EDIT_CLOSE':
            return Object.assign({}, state, {showEditTaskForm: false});


        case 'HOLIDAY_ADD_SHOW':
            return Object.assign({}, state, {showAddHolidayForm: true});
        case 'HOLIDAY_ADD_CLOSE':
            return Object.assign({}, state, {showAddHolidayForm: false});
        case 'USER_ADD_SHOW':
            return Object.assign({}, state, {showAddUserForm: true});
        case 'USER_ADD_CLOSE':
            return Object.assign({}, state, {showAddUserForm: false});
        case 'USER_EDIT_SHOW':
            return Object.assign({}, state, {showEditUserForm: true});
        case 'USER_EDIT_CLOSE':
            return Object.assign({}, state, {showEditUserForm: false});
        case 'USER_PROJECTS_SHOW':
            return Object.assign({}, state, {showUserProjectsForm: true});
        case 'USER_PROJECTS_CLOSE':
            return Object.assign({}, state, {showUserProjectsForm: false});
        case 'PROJECT_FILTER':
            return Object.assign({}, state, {projectFilterId: action.data});
        default:
            return state;
    }
}
const task = (state = {items: []}, action) => {
    switch (action.type) {

        case 'TASKS_LOADED':
            return Object.assign({}, state, {items: action.data});
        case 'TASK_EDIT_LOADED':
            return Object.assign({}, state, {taskForEdit: action.data});
        default:
            return state;

    }
}

const client = (state = {items: []}, action) => {
    switch (action.type) {

        case 'CLIENTS_LOADED':
            return {items: action.data};
        default:
            return state;
    }
}
const report = (state={},action) => {
    switch (action.type) {
        case 'REPORT_BYPROJECT_LOADED':
            console.log('report data:',action.data);
            return Object.assign({},state,{reportByProject: action.data});
        case 'REPORT_ONE_PROJECT_LOADED':
            console.log('report data:',action.data);
            return Object.assign({},state,{reportOneProject: action.data});
        default:
            return state;
    }
}

const action = (state = {items: []}, action) => {
    switch (action.type) {
        // case 'TOGGLE_NEW_PROJECT':
        //     // alert(1);
        //     return {items: state.items, newEmptyVisible: !state.newEmptyVisible, context: state.context};
        case 'REMOVE_ACTION':

            alert(action.id);

            return state;
        case 'ACTIONS_LOADED':
            // console.log(action, 'it is an action');
            console.log(" ___-- Actions -- LOAD --___")
            console.log(action.data)

            return {items: action.data};

        default:
            return state;
    }
}

const project = (state = {items: []}, action) => {
    // console.log('project dispatcher', state, action)
    switch (action.type) {
        // case 'TOGGLE_NEW_PROJECT':
        //     // alert(1);
        //     return {items: state.items, newEmptyVisible: !state.newEmptyVisible, context: state.context};
        case 'REMOVE_PROJECT':

            alert(action.id);

            return state;
        case 'PROJECTS_LOADED':

            return Object.assign({}, state, {items: action.data});

        case 'PROJECT_EDIT_LOADED':
            return Object.assign({}, state, {projectForEdit: action.data});
        default:
            return state;
    }
}

const holiday = (state = {items: []}, action) => {
    switch (action.type) {
        case 'HOLIDAYS_LOADED':

            return Object.assign({}, state, {items: action.data});
        default:
            return state;

    }
};

const user = (state = {items: []}, action) => {
    switch (action.type) {
        case 'USERS_LOADED':
            return Object.assign({}, state, {items: action.data});

        case 'USER_EDIT_LOADED':
            return Object.assign({}, state, {userForEdit: action.data});
        case 'USER_PROJECTS_LOADED':
            return Object.assign({}, state, {userForEdit: action.user, userProjects: action.data});
        default:
            return state;
    }
};

const reducer = combineReducers({
    authorization,
    loginForm,
    task,
    client,
    project,
    action,
    UI,
    holiday,
    user,
    report,
    form: formReducer
});

export default reducer;
