import React, {Component, Fragment} from "react";
import {Field, Formik} from "formik";


import DatePicker, {registerLocale} from "react-datepicker"
import ru from "date-fns/locale/ru"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import actions from "../../actions";
import {connect} from "react-redux";
import Network from "../../Network";

registerLocale("ru", ru); // register it with the name you wa

//
// function ymd(dateItem) {
//     var mm = dateItem.getMonth() + 1; // getMonth() is zero-based
//     var dd = dateItem.getDate();
//
//     return [dateItem.getFullYear(),
//         (mm > 9 ? '' : '0') + mm,
//         (dd > 9 ? '' : '0') + dd
//     ].join('-');
// }


class ActionForm extends Component {


    constructor(props, context) {
        super(props, context);
        this.loadActions=this.loadActions.bind(this);
    }

    render() {
        // let actionId = this.props.actionId === undefined ? "" : this.props.actionId;

        // console.log('formik:::',this.props.id);
        return (
            <Formik
                initialValues={{
                    userId: this.props.userId,
                    spent: this.props.spent,
                    description: this.props.description,
                    day: this.props.date,
                    taskId: this.props.taskId,
                    id: this.props.id
                }}

                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        let v = Object.assign({}, values);
                        // console.log("VALUES")
                        // console.log(values)
                        // console.error(v);
                        v.day = values.day.ymd();
                        // alert(JSON.stringify(v, null, 2));
                        let url = "/api/action/create";
                        let method = "POST";
                        if (v.id > 0) {
                            url = "/api/action/edit";
                            method = "PUT";
                        }

                        let that = this
                        fetch(url, {
                            method: method, // *GET, POST, PUT, DELETE, etc.
                            // mode: 'cors', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            // credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.props.token
                                // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            // redirect: 'follow', // manual, *follow, error
                            // referrerPolicy: 'no-referrer', // no-referrer, *client
                            body: JSON.stringify(v) // body data type must match "Content-Type" header
                        }).then((response) => {
                            return response.json();
                        })
                            .then((data) => {

                                console.log(data);
                                setTimeout(
                                    that.loadActions, 500
                                );
                            });
                        console.log(v);
                        setSubmitting(false);

                        this.props.close();
                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <label
                                className={"col-sm-9 col-form-label"}>Date for
                                action</label>
                            <hr/>
                            <DatePicker
                                selected={values.day}
                                dateFormat="yyyy-MM-dd"
                                inline

                                showPreviousMonths
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="day"
                                todayButton="Today"
                                locale="ru"
                                onChange={date => setFieldValue('day', date)}
                            />
                        </div>

                        <div className={"form-group"}>
                            <label
                                htmlFor="taskId" className={"col-sm-9 col-form-label"}>Task</label>
                            <Field name="taskId" as="select" className="my-select form-control">
                                <option value="0">---------</option>
                                {this.renderTasks()}
                            </Field>
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor="description" className={"col-sm-9 col-form-label"}>
                                Description
                            </label>
                            <input className={"form-control"}
                                   type="text"
                                   name="description"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.description}
                            />
                            {errors.email && touched.email && errors.email}
                        </div>

                        <input
                            type="hidden"
                            name="id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.id}
                        />
                        <input
                            type="hidden"
                            name="userId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userId}
                        />


                        <div className="form-group">
                            <label htmlFor="spent" className={"col-sm-9 col-form-label"}>Time
                                spent: {values.spent}</label>
                            <input type="range"
                                   name={"spent"}
                                   min="0" max="8"
                                   className="form-control-range"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.spent}
                            />
                        </div>

                        <button type="submit" disabled={isSubmitting}
                                className="btn btn-primary mb-2"
                        >
                            Submit
                        </button>
                    </form>
                )}
            </Formik>);

    }

    renderTasks() {
        console.log('rendering tasks', this.props.task)

        function sortTask(i1, i2) {
            if (i1.project.client.name > i2.project.client.name) return 1;
            if (i1.project.client.name < i2.project.client.name) return -1;
            if (i1.project.name > i2.project.name) return 1;
            if (i1.project.name < i2.project.name) return -1;
            if (i1.name > i2.name) return 1;
            if (i1.name < i2.name) return -1;
            return 0;
        }

        this.props.task.items.sort(sortTask);

        return <Fragment>
            {this.props.task.items.map(it => <option key={it.id}
                                                     value={it.id}>{it.project.client.name}/{it.project.name}/{it.name}</option>)}
        </Fragment>
    }

    loadActions() {
        Network.loadActions(
            this.props.token,
            this.props.userId,
            this.props.start,
            this.props.end,
            this.props.actionsLoaded
        );
    }

}

const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userId: state.authorization.payload.id,
        // project: state.project,
        start: new Date(state.UI.actionsListStart),
        end: new Date(state.UI.actionsListEnd),
        // end: new Date(state.UI.actionsListEnd),
        // actions: state.action,
        task: state.task
    }
}

const mapDispatchToProps = dispatch => {
    return {

        actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data))

        // tasksLoaded: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.task.tasksLoaded(data))
        // },
        // closeNewActionForm: (data) => {
        //     // console.log('tasks here');
        //     return dispatch(actions.UI.closeActionForm(data))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ActionForm);
