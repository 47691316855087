import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducer from "./reducers/reducer";
import './bootstrap.css';
import {loadState} from "./Storage";
import {saveState} from "./Storage";
import throttle from 'lodash.throttle';

var redux = require("redux");
var Provider = require("react-redux").Provider;

Date.prototype.ymd=function(){
    var dateItem=this;
    var mm = dateItem.getMonth() + 1; // getMonth() is zero-based
    var dd = dateItem.getDate();

    return [dateItem.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
    ].join('-');
}



const persistedState = loadState();
var store = redux.createStore(reducer, persistedState);
store.subscribe(
    throttle(() => {
        let state = store.getState();
        let forSave = {UI:state.UI,authorization:state.authorization};
        console.log('saving state',forSave)
        saveState(forSave)
    }, 3000)
);





ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
