import React, {Component, Fragment} from "react";
import DatePanel from "./navigation/DatePanel";
import {connect} from "react-redux";
import Network from "../Network";
import actions from "../actions";

import './reports.css'

class ReportByProjectBody extends Component{

    constructor(props, context) {
        super(props, context);

    }

    render() {

        return <div>
            <div className={"row"} style={{height: "35px"}}></div>
            <DatePanel>
            <h1>Report By Project</h1>
            </DatePanel>
            <div className={"project-report-grid report"}>
                <div className={"cell cell-head"}>
                    Client /
                    Project
                </div>
                <div className={"cell cell-head"}>
                    User
                </div>
                <div className={"cell cell-head"}>
                    Hours
                </div>
            </div>
            {this.renderProjects()}
{/*<div>{this.props.startDate.ymd()} - {this.props.endDate.ymd()}</div>*/}
        </div>;
    }

    renderProjects(){
        if(this.props.report==undefined){
            return <h2>Loading...</h2>;
        }else{
          return <Fragment>
            {this.props.report.map(it=>{
                return (<div key={it.projectId+'.'+it.userId} className={"project-report-grid report"}>
                    <div className={"cell"}>
                        {it.clientName}
                    /
                        {it.projectName}
                    </div>
                    <div className={"cell"}>
                        {it.userName}
                    </div>
                    <div className={"cell"}>
                        {it.timeSpent}
                    </div>
                </div>)
            })}
          </Fragment>;
        }
    }
    loadReport(){

        Network.loadByProjectsReport(
            this.props.token,
            this.props.startDate,
            this.props.endDate,
            this.props.reportLoaded
        );
    }
    componentDidMount() {
        this.loadReport();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.startDate.ymd() != prevProps.startDate.ymd() || this.props.endDate.ymd()!=prevProps.endDate.ymd()){
            this.loadReport();
        }
    }

}

const mapStateToProps = function(state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token!=null,
        loginDisplay: state.loginForm.visible,
        startDate: new Date(state.UI.actionsListStart),
        endDate: new Date(state.UI.actionsListEnd),
        report: state.report.reportByProject
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reportLoaded:(data)=>dispatch(actions.report.reportByProjectsLoaded(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReportByProjectBody);