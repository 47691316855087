import React, {Component} from "react";
import {connect} from "react-redux";
import actions from "../../../actions";
import Network from "../../../Network";

import './ProjectList.css';

class ProjectList extends Component {

    constructor(props, context) {
        super(props, context);
        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(e) {
        console.log(e.target);
        let id = e.target.dataset.id;
        this.props.projectClicked(id);
    }

    render() {
        console.log('prList',this.props);
        function sortProjects(i1, i2) {
            if (i1.client.name > i2.client.name) return 1;
            if (i1.client.name < i2.client.name) return -1;
            if (i1.name > i2.name) return 1;
            if (i1.name < i2.name) return -1;
            return 0;
        }

        // this.props.task.items.sort(sortTask);
        this.props.project.items.sort(sortProjects)
        let list;
        let that=this;
        if (this.props.project && this.props.project.items.length > 0) {
            list = this.props.project.items.map(function (item) {
                let active = item.id==that.props.projectFilterId;
                return <ProjectItem key={item.id} item={item} active={active}
                                    click={that.clickHandler}/>;
            });
        }
        return (
            <ul className="nav flex-column">
                {list}
            </ul>);
    }


    componentDidMount() {
        // console.log("MOUNTED PROJ");
        // this.loadProjects();
        Network.loadProjects(this.props.token, this.props.projectsLoaded);

    }


}


const mapStateToProps = function (state) {

    return {
        project: state.project,
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        loginDisplay: state.loginForm.visible,
        activeTab: state.UI.activeTab,
        projectFilterId: state.UI.projectFilterId,
    }
};

const mapDispatchToProps = dispatch => {
    return {

        projectsLoaded: (data) => dispatch(actions.project.projectsLoaded(data)),
        projectClicked: function (id) {
            dispatch(actions.UI.setActiveTab('projectFilter'));
            dispatch(actions.UI.filterProject(id));
        },
    }
};

function ProjectItem(props) {
    let item = props.item;
    let active = props.active ? ' active ':'';
    return (
        <li className="nav-item " key={item.id}>
            <a className={"nav-link pr-grid"+active} href="#" onClick={props.click} data-id={item.id}>
                <div data-id={item.id} className={"pr-cell pr-client"}>{item.client.name}</div>
                <div data-id={item.id} className={"pr-cell project"}>{item.name}</div>
            </a>
        </li>);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
