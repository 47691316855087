import React, {Component} from 'react';

import "react-datepicker/dist/react-datepicker.css";
import actions from "../../actions";
import {connect} from "react-redux";
import ActionForm from "./ActionForm";


class EditActionForm extends Component {

    render() {

        return (
            <div className="modal" tabIndex="-1" role="dialog" style={{display: "block",}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter your data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={this.props.closeEditActionForm}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.renderForm()}
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn btn-danger mb-2" data-dismiss="modal"
                                    onClick={this.props.closeEditActionForm}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderForm() {
        if(this.props.item === undefined){
            return <span> loading ...</span>
        }
        let item = this.props.item;
        console.log('rendeding form', item);
        return <ActionForm
            id={item.id}
            userId={item.user.id}
            spent={item.spent}
            description={item.description}
            date={new Date(Date.parse(item.day))}
            taskId={item.task.id}

            close={this.props.closeEditActionForm}
        />
    }

    componentDidMount() {
        this.loadTasks();
        this.loadEditableAction();

    }
    loadEditableAction(){
        let url = "/api/action/id/"+this.props.editableActionId;
        let that = this;
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.props.token
            }
        })
            .then(response => response.json())
            .then(function (data) {
                console.log(data);
                console.log('tasks fetched');
                that.props.itemLoaded( data);
                // that.props.tasksLoaded(data);
            }) // JSON-строка полученная после вызова `response.json()`
            .catch(error => console.error(error))
    }

    loadTasks() {
        let url = "/api/task/list";
        console.log('start fetch task list;')
        let that = this;
        fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.props.token
            }
        })
            .then(response => response.json())
            .then(function (data) {
                console.log(data);
                console.log('tasks fetched');
                that.props.tasksLoaded(data);
            }) // JSON-строка полученная после вызова `response.json()`
            .catch(error => console.error(error))
    }

}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userId: state.authorization.payload.id,
        project: state.project,
        start: new Date(state.UI.actionsListStart),
        end: new Date(state.UI.actionsListEnd),
        actions: state.action,
        task: state.task,
        item: state.UI.editableAction,
        // selectedDay: new Date(Date.parse(state.UI.newActionDay)),
        editableActionId: state.UI.actionEditId
    }
}

const mapDispatchToProps = dispatch => {
    return {

        actionsLoaded: (data) => dispatch(actions.action.actionsLoaded(data)),

        tasksLoaded: (data) => {
            // console.log('tasks here');
            return dispatch(actions.task.tasksLoaded(data))
        },
        itemLoaded: (data) =>dispatch(actions.UI.loadedActionForEdit(data)),

        closeEditActionForm: (data) => {
            // console.log('tasks here');
            return dispatch(actions.UI.closeEditActionForm(data))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditActionForm);