
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';

class LoginForm extends Component {


    render () {
          return (
            <form id="loginForm" onSubmit={this.props.onSubmit()}>
                <Field
                    name="email"
                    component="input"
                    type="text"
                    placeholder="Username"
                />
                <hr/>
                <Field
                    name="password"
                    component="input"
                    type="password"
                    placeholder="Password"
                />

            </form>
        );
    }
}


LoginForm = reduxForm ({
    form: 'login'
}) (LoginForm);

export default LoginForm;