import React, {Component} from "react";

import './DatePanel.css'
import {connect} from "react-redux";
import actions from "../../actions";


class DatePanel extends Component {
    constructor(props) {
        super(props);
        this.showDateForm = this.showDateForm.bind(this);
    }

    showDateForm(e) {
        let which = e.target.dataset.which;

            this.props.showActionListDate(which);

    }

    render() {
        return <div className={"datePanel"} style={{border: '1px solid black', borderRadius: '5px'}}>


                <a className={"nav-link startDate"} href="#" data-which="start" onClick={this.showDateForm}>
                    {this.props.startDate.ymd()}
                </a>



            <div>
                {this.props.children}
            </div>
            <a className={"nav-link endDate"} href="#" data-which="end" onClick={this.showDateForm}>
                {this.props.endDate.ymd()}
            </a>
        </div>
    }
}


const mapStateToProps = function (state) {

    return {
        authorized: state.authorization.token != null,
        loginDisplay: state.loginForm.visible,
        startDate: new Date(state.UI.actionsListStart),
        endDate: new Date(state.UI.actionsListEnd),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginClick: function () {

        },
        showActionListDate:function(data) {
            dispatch(actions.UI.openActionListDateForm(data))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePanel);