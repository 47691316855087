import React, {Component} from "react";
import {connect} from "react-redux";
import actions from "../actions";
import DateForm from "./form/DateForm";

class DateFormPopup extends Component {


    constructor(props) {
        super(props)
        this.dateHandler = this.dateHandler.bind(this)
    }

    dateHandler(data) {
        console.log(this, data);
        let dispatchData = {dateType: this.props.datePopupType, date: data};


        this.props.changeActionListDate(dispatchData);
    }

    render() {//showActionListDate
        let show = {display: 'none'};
        if (this.props.showActionListDate) {
            show = {display: 'block'};
        }
        let formDate = this.props.datePopupType == 'start' ? this.props.startDate : this.props.endDate;
        return <div className="modal" tabIndex="-1" role="dialog" style={show}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Choose <b>{this.props.datePopupType}</b> date</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={this.props.closeWindow}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DateForm day={formDate} chooseHandler={this.dateHandler}/>
                    </div>
                    <div className="modal-footer">

                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={this.props.closeWindow}>Close
                        </button>
                    </div>
                </div>
            </div>
        </div>

    }

}


const mapStateToProps = function (state) {

    return {
        authorized: state.authorization.token != null,
        loginDisplay: state.loginForm.visible,
        startDate: new Date(state.UI.actionsListStart),
        endDate: new Date(state.UI.actionsListEnd),
        showActionListDate: state.UI.actionListDateFormShow,
        datePopupType: state.UI.actionListDateType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeActionListDate: function (data) {
            dispatch(actions.UI.setDate(data));
        },
        closeWindow: function () {
            dispatch(actions.UI.closeActionListDateForm({}))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateFormPopup);