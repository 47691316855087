import React, {Component} from "react";
import {connect} from "react-redux";

class NavigationLink  extends Component{

    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick = (e) => {

        e.preventDefault();
        console.log('The link was clicked: ' + this.props.name);
        if(this.props.onClick){
            this.props.onClick(this.props.id);
        }

    };

    render() {

        // console.log("Nav Link")
        // console.log(this.props)
        let disabled = this.props.disabled?"disabled":"";
        // console.log(disabled);
        let name =  this.props.disabled?'':this.props.name;
        let active = this.props.active?' active ':'';
        return (
            <li className={"nav-item"} >
                <a className={"nav-link " +disabled +active}
                   href="#" onClick={this.handleClick}>{name}
                </a>
            </li>
        );
    }
}


const mapStateToProps = function (state) {

    return {
        token: state.authorization.token,
        authorized: state.authorization.token != null,
        userInfo: state.authorization.payload,
    }
};

const mapDispatchToProps = dispatch => {
    return {

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(NavigationLink)